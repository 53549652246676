function Text() {
  return (
    <>
      <h1 className="tipo-01"><b>Preguntas Frecuentes</b></h1>
      <div>
        <b>1. ¿Qué debe cumplir mi empresa para postular a una licitación en Minera Los Pelambres? </b>
        <br/><br/>
        <p>En primera instancia para postular a licitaciones en Minera Los Pelambres, la empresa debe dar cumplimiento a las normativas de ética y compliance de la compañía, y además velar por la Seguridad y Salud Ocupacional.</p>

        <b>2. ¿Debe estar formalizada ante SII mi empresa para postular a una licitación en Minera Los Pelambres?</b>
        <br/><br/>
        <p>Sí, la empresa debe estar debidamente formalizada ante SII, con un deseable de 6 meses de antigüedad. </p>

        <b>3. ¿Qué documentos debo presentar para asegurar mi cumplimiento en Seguridad y Salud Ocupacional?</b>
        <br/><br/>
        <p>Previo a ser invitado a una licitación, los proveedores deben presentar los siguientes certificados con historial de los últimos 12 meses: </p>
        <ul>
          <li>Certificado de cumplimiento de obligaciones laborales y previsionales.</li>
          <li>Certificado de afiliación mutualidad.</li>
          <li>Tasa de Accidentabilidad.</li>
        </ul>

        <b>4. ¿Debo tener experiencia para participar en una licitación?</b>
        <br/><br/>
        <p>No es excluyente, sin embargo, dependerá del tipo de servicio el nivel de experiencia que debe tener la empresa.</p>

        <b>5. ¿Es requisito contar con una boleta de garantía para participar en licitación?</b>
        <br/><br/>
        <p>No es requisito. Solo aplica para contratos mayores presentar una Boleta de Seriedad de la Oferta, esto se indica en los documentos de licitación.</p>

        <b>6. ¿Qué es el programa de acompañamiento y como beneficia a los proveedores locales?</b>
        <br/><br/>
        <p>Es un programa de acompañamiento grupal e individual dirigido a proveedores locales, el cual consiste en un entrenamiento para que pueda comprender el proceso de postulación a licitaciones en Minera Los Pelambres. Los cupos son limitados, por lo que los proveedores deberán postular.</p>

        <b>7. Si participo en el acompañamiento de proveedores ¿me adjudicare un proceso de licitación?</b>
        <br/><br/>
        <p>No, participar del programa de acompañamiento no asegura que el proveedor ganará una licitación inmediata o en el futuro. </p>

        <b>8. ¿Cuánto dura el programa de acompañamiento de proveedores?</b>
        <br/><br/>
        <p>Tendrá una duración de 2 meses una vez que confirmada su participación. </p>

        <b>9. ¿El programa de acompañamiento se desarrollará de manera virtual?</b>
        <br/><br/>
        <p>Se realizará en una modalidad mixta; presencial y virtual.</p>

        <b>10. ¿La asesoría solo permitirá entender como se ejecutan las licitaciones en Minera Los Pelambres?</b>
        <br/><br/>
        <p>Así es, sin embargo, el acompañamiento entregará herramientas que le permitirá estar mejor preparado para postular a otras licitaciones/cotizaciones más allá de Minera Los Pelambres.</p>

        <b>11 ¿Dónde tiene que estar ubicada mi empresa para postular al programa?</b>
        <br/><br/>
        <p>Su empresa debe estar ubicada dentro de la provincia del Choapa, con dirección comercial en cualquiera de sus comunas y/o localidad.</p>
      </div>
    </>
  );
}

export default Text;
