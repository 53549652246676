import React from "react";
import Navi from "./navi";
import Preguntas from "./preguntas";
import Logo from "../img/logo_desktop.png";
import LogoFooter from "../img/logo_image_footer.png";
import OtroFooter from "../img/otro_image_footer.png";

function Frecuentes() {
  return (
    <>
      <div className="contain">
        <div className="mobile-version"></div>
        <div className="logo" />
        <div className="info">
          <div className="img-logo main-logo">
            <img src={Logo} width={413}/>
          </div>
          <Navi />
          <Preguntas />
        </div>
      </div>
      <footer>
        <div className="border-footer">Un programa de:</div>
        <div className="img-logo-footer">
          <img src={LogoFooter} width={256}/>
        </div>
        <div className="border-footer-territoria">
          <p>Ejecutados con el apoyo de:</p>
          <div className="img-logo-footer">
          <img src={OtroFooter} width={100} />
        </div>
        </div>
      </footer>
    </>
  );
}

export default Frecuentes;
