import { useState } from "react";
import Card01 from "../img/card01.png";
import Card02 from "../img/card02.png";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LogoFooter from "../img/logo_image_footer.png";

function Card() {
  const [show, setShow] = useState(false);
  const [show02, setShow02] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose02 = () => setShow02(false);
  const handleShow02 = () => setShow02(true);

  return (
    <>
      <div className="card-info" onClick={handleShow}>
        <img src={Card01} />
        <h3 className="tipo-01">
          <b>Canal de consultas</b>
        </h3>
        <ul className="card-contain">
          <li>
            Envíanos tus dudas sobre el proceso de licitación y recibirás una
            respuesta personalizada. Si la explicación requiere un mayor
            acompañamiento, nos contactaremos para entregarte la ayuda
            necesaria. Este canal estará disponible entre el{" "}
            <b>8 de julio y el 23 de octubre de 2024.</b>
          </li>
        </ul>
      </div>
      {/* onClick={handleShow02} */}
      <div className="card-info">
        <img src={Card02} />
        <h3 className="tipo-02">
          <b>
            Postulación <br /> Programa de Acompañamiento
          </b>
        </h3>
        <ul className="card-contain">
            <b>Plazo de postulación finalizado.</b>
            {/* <b>Postula hasta el 23 de agosto</b> a los entrenamientos
            personalizados, a través del que podrás aprender a realizar
            licitaciones. Los cupos son limitados. */}

        </ul>
      </div>
      {/* MODALES */}
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6} md={4}>
                <img src={LogoFooter} width={256}/>
              </Col>
              <Col xs={12} md={12}>
                <h3 className="tipo-01">
                  <b>Canal de consultas</b>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={8}>
                <h5>
                  <b>Consultas específicas:</b>
                </h5>
                <br />
                <div>
                  Consiste en la realización de consultas del proceso de
                  postulación. Está dirigido a empresas que comprendan y
                  conozcan el detalle de requisitos, formularios y procesos de
                  postulación, pero que tengan dudas puntuales al respecto. Las
                  consultas pueden estar en la línea de: Indicadores de
                  desempeño (KPI), Reglamento Contratistas Seguridad y Salud
                  Ocupacional, Reajustes (sueldos, etc), Estados de pago,
                  métodos de pago, etc., Aportes (contratistas, MLP),
                  Empleabilidad local, Seguros y garantías (salud, etc),
                  Tratamiento de facturas, Normativa (anticorrupción; leyes
                  21.121, 21.132, otras requeridas), entre otras.
                </div>
                <br />
              </Col>
              <Col xs={12} md={4} className="card-lightbox">
                <div className="contain-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="boton-form"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeNsNDrVzD_6qPLU4-rOAvb7M1PskLTWuYE3nIBC36os5y3MA/viewform"
                  >
                    <img src={Card01} />
                    <h4 className="tipo-01">
                      <b>
                        Ir al <br /> formulario
                      </b>
                    </h4>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal size="lg" show={show02} onHide={handleClose02}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6} md={4}>
                <img src={LogoFooter} width={256}/>
              </Col>
              <Col xs={12} md={12}>
                <h3 className="tipo-02">
                  <b>Postulación Programa de Acompañamiento</b>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={8}>
                <h5>
                  <b>
                    Programa de entrenamiento para la elaboración de propuestas:
                  </b>
                </h5>
                <br />
                <div>
                  Consiste en la postulación por parte del proveedor local a un
                  proceso de entrenamiento grupal, e individual en algunos casos
                  también, para comprender el proceso de postulación a
                  licitaciones. Al final de este proceso el Proveedor estará en
                  condiciones de enfrentar procesos de postulación de manera
                  autónoma y ser competitivo en estos procesos.
                  <br /><br/>
                  Para postular a este programa, el proveedor debe tener un
                  nivel de gestión adecuado, ya que el entrenamiento será
                  enfocado al proceso de postulación y no a la asesoría en la
                  gestión de las empresas. Para postular, el proveedor debe
                  comprometerse a completar un formulario de inscripción, un
                  diagnóstico y participar de una entrevista. Además, los
                  seleccionados tendrán que participar del 100% de las
                  actividades grupales y también a las individuales, en los
                  casos que corresponda.
                </div>
                <br />
              </Col>
              <Col xs={12} md={4} className="card-lightbox">
                <div className="contain-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="boton-form"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSe_UiEKavvYi05hJKEUMPpRQ3DzL_4dVcHzPcdycSOX1b9MUg/viewform"
                  >
                    <img src={Card02} />
                    <h4 className="tipo-02">
                      <b>
                        Ir al <br /> formulario
                      </b>
                    </h4>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default Card;
