import React from "react";
import Navi from "./navi";
import Logo from "../img/logo_desktop.png";
import LogoFooter from "../img/logo_image_footer.png";
import OtroFooter from "../img/otro_image_footer.png";
import Pdf from "../img/icono-PDF.png";
import Video from "../img/reproductor-de-video.png"

function Documentacion() {
  return (
    <>
      <div className="contain">
        <div className="mobile-version"></div>
        <div className="logo" />
        <div className="info">
          <div className="img-logo main-logo">
            <img src={Logo} width={413} />
          </div>
          <Navi />
          <h1 className="tipo-01">
            <b>Documentación</b>
          </h1>
          <ul>
          <li><img src={Pdf} width={25}/> <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://mlp.territoriaconsultores.cl/PPT_Ciclo_Tips_para_elaborar_una_propuesta.pdf">Programa  de Acompañamiento intensivo de proveedores Locales del Valle del Choapa</a></li>
          </ul>
          <br/>
          <br/>
          {/* className="tipo-01" */}
          <p><b>Talleres</b></p>
          <div className="talleres01">
          <ul>
          <li><img src={Pdf} width={25}/> <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://mlp.territoriaconsultores.cl/PPT_Taller1.pdf">Taller Nº 1</a></li>
          <br/>
          <li><img src={Video} width={25}/> <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1l7nWb9i20wBYWOg7syFwpyN9CjJHxmI4/view">Ver Video Nº 1</a></li>
          </ul>
          </div>
          <div className="talleres02">
          <ul>
          <li><img src={Pdf} width={25}/> <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://mlp.territoriaconsultores.cl/PTT_Taller2.pdf">Taller Nº 2</a></li>
          <br/>
          <li><img src={Video} width={25}/> <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1z8iaWh7NUEdRVnCX3qaLJeHGOeWXSEMd/view">Ver Video Nº 2</a></li>
          </ul>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      </div>
      <footer>
        <div className="border-footer">Un programa de:</div>
        <div className="img-logo-footer">
          <img src={LogoFooter} width={256} />
        </div>
        <div className="border-footer-territoria">
          <p>Ejecutados con el apoyo de:</p>
          <div className="img-logo-footer">
            <img src={OtroFooter} width={100} />
          </div>
        </div>
      </footer>
    </>
  );
}

export default Documentacion;