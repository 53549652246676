function Text() {
  return (
    <>
      <h1 className="tipo-01">
        Te damos la bienvenida al
        <b>
        {" "}programa intensivo de acompañamiento para proveedores{" "}
        </b>
        de la Provincia de Choapa
      </h1>
      <div>
        Este es un programa de Minera Los Pelambres, que busca ser una herramienta de apoyo para las empresas locales que cuentan con un nivel de gestión adecuado para postular a licitaciones de la compañía.
      </div>
      <br/>
      <p>El programa cuenta con dos líneas de apoyo:</p>
      <ul>
        <li>Canal de Consultas específicas del proceso de licitación.</li>
        <li>Programa de entrenamiento para la elaboración de propuestas.</li>
      </ul>
      <br/>
    </>
  );
}

export default Text;
