import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./contain/home";
import Fq from "./contain/frecuentes";
import Doc from "./contain/documentacion";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/frecuentes" element={<Fq/>} />
        <Route path="/documentacion" element={<Doc/>} />
      </Routes>
    </BrowserRouter>
    // <Home/>
  );
}

export default App;
